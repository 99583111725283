
/*
This class defines the basic parameters of a station. Basically a location/name placeholder.
*/

//import timeline from "ol-ext/control/Timeline";

class timeseries_doc {
    constructor(time_series_json) {
        this._doc_type = time_series_json.docType ?? undefined;
        this._version  = time_series_json.version ?? undefined;
        this._timeseries_data = this.parse_time_series(time_series_json);

    }
    parse_time_series(time_series_json) {
        let timeseries_data = {};
        for(let column_key in time_series_json.header.columns) {
            let ts_col = new ts_column(column_key, time_series_json.header.columns[column_key],
                time_series_json.data);
            timeseries_data[ts_col.name] = ts_col;
        }
        return timeseries_data;
    }
}
class ts_column {
    constructor(column_key, ts_column_json, timeseries_data) {
        this._key = column_key ?? undefined
        if(ts_column_json !== undefined) {
            this._data_type = ts_column_json.dataType ?? undefined;
            this._id = ts_column_json.id ?? undefined;
            this._name = ts_column_json.name ?? undefined;
            this._units = ts_column_json.units ?? undefined;
        }
        this._records = [];
        if(column_key !== undefined) {
            this._records = this.parse_records(this._key, timeseries_data);
        }
    }

    set name(obs_name) {
         this._name = obs_name;
    }
    set key(key_value) {
       this._key = key_value;
    }
    set id(id_value) {
        this._id = id_value;
    }
    set data_type(data_type_value) {
        this._data_type = data_type_value;
    }

    set units(units_value) {
        this._units = units_value;
    }
    set records(records_value) {
        this._records.push(records_value);
    }

    get name() {
        return this._name;
    }
    get key() {
        return this._key;
    }
    get id() {
        return this._id;
    }
    get data_type() {
        return this._data_type
    }

    get units() {
        return this._units;
    }
    get records() {
        return this._records;
    }
    get record_count() {
        return this._records.length;
    }
    parse_records(key, timeseries_data) {
        let ts_records = []
        for(let row_ndx = 0;  row_ndx < timeseries_data.length; row_ndx++)
        {
            let row = timeseries_data[row_ndx];
            let timestamp = row.ts;
            let column_data = undefined;
            if(key in row.f) {
                column_data = row.f[key].v;
            }
            else {
                column_data = "N/A";
            }
            let ts_col = new timeseries_record(timestamp, column_data)
            ts_records.push(ts_col)
        }
        return(ts_records);
    }
}

class timeseries_record {
    constructor(timestamp, value) {
        this._timestamp = timestamp ?? undefined;
        this._value = value ?? undefined;
    }

    get timestamp()
    {
        return this._timestamp;
    }
    get value() {
        return this._value;
    }
}
export default class StationData { // eslint-disable-line no-unused-vars
    constructor(geoJsonData) {
        this.geoJsonData = geoJsonData;

        this._columns_metadata = this.get_timeseries_data();
        this._dates = this.get_date_list();

    }

    get id() {
        return this.geoJsonData.id ?? undefined;
        //return this._id;
    }
    get site_name() {
        return this.geoJsonData.properties.site_name ?? undefined;
        //return this._site_name;
    }
    get site_type() {
        return this.geoJsonData.properties.site_type ?? undefined;
        //return this._site_type;
    }
    get latitude() {
        return this.geoJsonData.geometry.coordinates[1] ?? undefined;
    }
    get longitude() {
        return this.geoJsonData.geometry.coordinates[0] ?? undefined;
    }
    get coordinates() {
        return [this.geoJsonData.geometry.coordinates[0] ?? undefined, this.geoJsonData.geometry.coordinates[1] ?? undefined];
    }
    get location() {
        return [this.geoJsonData.geometry.coordinates[1] ?? undefined, this.geoJsonData.geometry.coordinates[0] ?? undefined];
    }
    get description() {
        return this.geoJsonData.properties.description ?? undefined;
        //return this._description;
    }
    get region() {
        return "";
        //return this._region;
    }
    get city() {
        return this.geoJsonData.properties.city ?? undefined;
        //return this._city;
    }
    get post_code() {
        return this.geoJsonData.properties.post_code ?? undefined;
        //return this._post_code;
    }
    get state_code() {
        return this.geoJsonData.properties.state_code ?? undefined;
        //return this._state_code;
    }
    get county() {
        return this.geoJsonData.properties.county ?? undefined;
        //return this._county;
    }
    get properties()
    {
        return this.geoJsonData.properties;
    }
    get usgs_site_id() {
        if('site_observations' in this.geoJsonData.properties) {
            if('usgs_sites' in this.geoJsonData.properties.site_observations) {
                return this.geoJsonData.properties.site_observations.usgs_sites.site_id ?? undefined;
            }
        }
        return undefined
    }
    get usgs_site_parameters() {
        if('site_observations' in this.geoJsonData.properties) {
            if('usgs_sites' in this.geoJsonData.properties.site_observations) {
                return this.geoJsonData.properties.site_observations.usgs_sites.parameters_to_query ?? undefined;
            }
        }
        return undefined;
    }
    get dataset_start_date() {
        return this.geoJsonData.properties[this.geoJsonData.properties.site_type].dataset_start_date ?? undefined;
    }
    get dataset_end_date() {
        return this.geoJsonData.properties[this.geoJsonData.properties.site_type].dataset_end_date ?? undefined;
    }
    get data_column_names() {
        let names = [];
        if(this._columns_metadata == undefined)
        {
            this.get_timeseries_data();
        }
        for(let col_key in this._columns_metadata._timeseries_data) {
            let rec = this._columns_metadata._timeseries_data[col_key];
            names.push(rec.name)
        }
        return names;
    }

    get latest_record_date() {
        if(this._dates.length)
        {
            return this._dates[0];
        }
        return undefined;
    }
    get column_names() {
        let names = [];
        if(this._columns_metadata == undefined)
        {
            this.get_timeseries_data();
        }
        for(let col_key in this._columns_metadata._timeseries_data) {
            let rec = this._columns_metadata._timeseries_data[col_key];
            names.push(rec.name);
        }
        return names;
    }
    get column_units() {
        let units = [];
        if(this._columns_metadata == undefined)
        {
            this.get_timeseries_data();
        }
        for(let col_key in this._columns_metadata._timeseries_data) {
            let rec = this._columns_metadata._timeseries_data[col_key];
            units.push(rec.units);
        }
        return units;
    }
    get_latest_obs_record(obs_name) {
        let latest_record = new ts_column(undefined, undefined, undefined);
        if(this._columns_metadata !== undefined) {
            let record = this._columns_metadata._timeseries_data[obs_name];
            latest_record.key = record.key;
            latest_record.data_type = record.data_type;
            latest_record.id = record.id;
            latest_record.name = record.name;
            latest_record.units = record.units;
            latest_record.records = record.records[0];
        }
        return latest_record;
    }
    get_timeseries_for_observation(obs_name) {
        let ts_record = undefined;
        if(obs_name in this._columns_metadata._timeseries_data) {
            ts_record = this._columns_metadata._timeseries_data[obs_name];
        }
        return ts_record;
    }
    get_timeseries_at_index(index_record) {
        let indexed_ts = [];
        if(this._columns_metadata !== undefined) {
            let record = this._columns_metadata._timeseries_data;

            let latest_record = ts_column(undefined, undefined, undefined);

            latest_record.key = record.key;
            latest_record.data_type = record.data_type;
            latest_record.id = record.id;
            latest_record.name = record.name;
            latest_record.units = record.units;
            latest_record.records = record.records[index_record];
            indexed_ts[latest_record.name] = latest_record
        }
        return indexed_ts;


    }

    get_records_for_date(date_time) {
        let data_row = {}
        if(this._columns_metadata !== undefined) {
            for(let obs_name in this._columns_metadata._timeseries_data) {
                let data_ts = this._columns_metadata._timeseries_data[obs_name];
                for(const ndx in data_ts.records)
                {
                    if(data_ts.records[ndx].timestamp == date_time)
                    {
                        let new_rec = new ts_column();
                        new_rec.key = data_ts.key;
                        new_rec.data_type = data_ts.data_type;
                        new_rec.id = data_ts.id;
                        new_rec.name = data_ts.name;
                        new_rec.units = data_ts.units;
                        new_rec.records = data_ts.records[ndx];

                        data_row[obs_name] = new_rec;

                    }
                }

            }
        }
        return data_row;
    }
    get_timeseries_data() {
        let columns_metadata = undefined;
        if('site_type' in this.geoJsonData.properties) {
            if ('timeseries' in this.geoJsonData.properties[this.geoJsonData.properties.site_type]) {
                columns_metadata =
                    new timeseries_doc(this.geoJsonData.properties[this.geoJsonData.properties.site_type].timeseries);
            }
        }
        return columns_metadata;
    }
    get_date_at_index(index) {
        if(this._dates.length && index < this._dates.length) {
            return this._dates[index];
        }
        return undefined;
    }
    get_date_list() {
        let date_list = []
        if(this._columns_metadata !== undefined) {
            for (let observation_name in this._columns_metadata._timeseries_data) {
                for(let record in this._columns_metadata._timeseries_data[observation_name].records)
                {
                    date_list.push(this._columns_metadata._timeseries_data[observation_name].records[record].timestamp);
                }
                break;
            }
        }
        return date_list;
    }
}

/*
export default class WQStation extends StationData { // eslint-disable-line no-unused-vars
    constructor() {
        super();
        //Prediction related vars
        this._forecast_date = undefined;
        this._ensemble_result = undefined;
        //Sample related vars
        this._sample_date = undefined;
        this._sample_value = undefined;
        this._issues_advisories = undefined;
        this._advisory_message = undefined;
    }
    get forecast_date() {
        return this._forecast_date;
    }
    get ensemble_result() {
        return this._ensemble_result;
    }
    get sample_date() {
        return this._sample_date;
    }
    get sample_value() {
        return this._sample_value;
    }
    get issues_advisories() {
        return this._issues_advisories;
    }
    get advisory_message() {
        return this._advisory_message;
    }
}
*/
