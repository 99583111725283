<template>
  <div class="row">
    <div v-if=has_water_temperature class="col-sm-3 fs-5">
      {{current_water_temperature}}
    </div>
    <div v-if=has_current_discharge class="col-sm-3  fs-5">
      {{current_discharge}}
    </div>
    <div v-if=has_gage_height class="col-sm-3  fs-5">
      {{gage_height}}
    </div>
  </div>
</template>

<script>
  //import DataAPI from "@/utilities/rest_api";
  //import {compass_array} from "@/utilities/direction_to_compass";
  import USGSAPI from "@/utilities/usgs_rest_api";

  //import moment from "moment";

  export default {
    name: 'USGSSitePage',
      props: {
        'p_longitude': {type: Number, default: undefined},
        'p_latitude': {type: Number, default: undefined},
        'p_usgs_site': {type: String, default: ''},
        'p_parameters': {type: String, default: undefined},
        'p_display_metric': {type: Boolean, default: false}
      },
    components: {
    },
    data() {
      return {
        usgs_site: undefined,
        site_data: {},
        parameters_map: {'00010': {'display': 'Water Temperature', 'units': 'Celcius'},
                        '00060': {'display': 'Discharge', 'units': 'ft^3/s'},
                        '00065': {'display': 'Gage Height', 'units': 'feet'}
        }
      }
    },
    mounted() {
      let vm = this;
      console.debug("USGSSitePage mounted.");
      this.usgs_site = this.p_usgs_site;
      //For now let's just get Water temp and Stream Flow.
      //Parameter codes found here: https://help.waterdata.usgs.gov/parameter_cd?group_cd=%
      //The ones we are using initially:
      // 00060 = Discharge, cubic feet per second
      // 00010 = Temperature, water, degrees Celsius
      //First let's check our cache to see if we have the data.
      let usgs_site_data = this.$store.getters.getObservingStationData(this.p_usgs_site);
      if(usgs_site_data == undefined) {
        USGSAPI.USGSGetInstantaneous({sites: this.p_usgs_site, parameters: this.p_parameters}).then(site_data => {
          //Create a dictionary with the different parameter types.
          site_data.forEach(rec => {
            if (rec.variable.variableDescription == "Temperature, water, degrees Celsius") {
              vm.site_data['00010'] = rec;
            }
            else if (rec.variable.variableDescription == "Discharge, cubic feet per second") {
              vm.site_data['00060'] = rec;
            }
            else if (rec.variable.variableDescription == "Gage height, feet") {
              vm.site_data['00065'] = rec;
            }
          });
          vm.$store.commit('setObservingStationData', {station: vm.usgs_site, data: site_data})
          vm.parse_timeseries(site_data);
        }).catch(error => {
          let error_message = '';
          let status_code = 404;
          if ('response' in error && error.response !== undefined) {
            status_code = error.response.status;
            if (typeof (error.response.data) !== "string") {
              if ('message' in error.response.data.error) {
                error_message = error.response.data.error.message;
              }
            } else {
              error_message = error.response.data;
            }
            console.error("Status code: " + status_code + ". Error Msg: " + error_message);
          } else {
            console.error(error);
          }
        });
      }
      else{
        //Create a dictionary with the different parameter types.
        usgs_site_data.forEach(rec => {
          if (rec.variable.variableDescription == "Temperature, water, degrees Celsius") {
            vm.site_data['00010'] = rec;
          }
          else if (rec.variable.variableDescription == "Discharge, cubic feet per second") {
            vm.site_data['00060'] = rec;
          }
          else if (rec.variable.variableDescription == "Gage height, feet") {
            vm.site_data['00065'] = rec;
          }

        });
        vm.parse_timeseries(usgs_site_data);

      }
      console.debug("USGSSitePage mounted finished.");
    },
    methods: {
      parse_timeseries: function(time_series) {
        time_series;
      },
      has_parameter: function(parameter)
      {
        if(parameter in this.site_data)
        {
          return(true);
        }
        return(false);
      },
      get_parameter: function(parameter) {
        if(parameter in this.parameters_map)
        {
          return(this.parameters_map[parameter]);
        }
        return(undefined);
    },
    },
    computed: {
      has_water_temperature: function() {
        return(this.has_parameter('00010'));
      },
      current_water_temperature: function() {
        let temperature = 'N/A';
        if(Object.keys(this.site_data).length) {
          try {
            let param_rec = this.get_parameter('00010');

            let data_rec = this.site_data['00010'].values[0].value[0]
            //let sample_time = moment(data_rec.dateTime);
            let value = data_rec.value;
            if (!this.display_metric) {
              value = (parseFloat(value) * 9 / 5) + 32;
              temperature = param_rec['display'] + ": " + value.toPrecision(3)+ " F";
            }
            else {
              temperature = param_rec['display'] + ": " + value + " C";

            }
          } catch (error) {
            console.error(error);
          }
        }
        return(temperature);
      },
      has_current_discharge: function() {
        return(this.has_parameter('00060'));
      },
      current_discharge: function() {
        let discharge = 'N/A';
        if(Object.keys(this.site_data).length) {
          let param_rec = this.get_parameter('00060');

          try {
            let data_rec = this.site_data['00060'].values[0].value[0]
            //let sample_time = moment(data_rec.dateTime);
            discharge = param_rec['display'] + ": " + data_rec.value + " " + this.site_data['00060'].variable.unit.unitCode;
          } catch (error) {
            console.error(error);
          }
        }
        return(discharge);

      },
      has_gage_height: function() {
        return(this.has_parameter('00065'))
      },
      gage_height: function() {
        let gage_height = 'N/A';
        if(Object.keys(this.site_data).length) {
          let param_rec = this.get_parameter('00065');
          try {
            let data_rec = this.site_data['00065'].values[0].value[0]
            gage_height = param_rec['display'] + ": " + data_rec.value + " " + this.site_data['00065'].variable.unit.unitCode;
          } catch (error) {
            console.error(error);
          }
        }
        return(gage_height);

      }

    }
  }
</script>
<style>
</style>
