<template>
    <ol-map id="single_site_map"
            ref="single_site_map"
            style="width: 100%; height: 100%; position:absolute"
            :loadTilesWhileAnimating="true"
            :loadTilesWhileInteracting="true">
        <ol-view ref="single_site_view"
                 :rotation="rotation"
                 :center="center"
                 :zoom="zoom"
                 projection="EPSG:4326">
        </ol-view>
        <ol-tile-layer>
            <ol-source-xyz url='https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'/>
        </ol-tile-layer>
        <ol-vector-layer ref="site_vector_layer">
            <ol-source-vector ref="site_vector_source">
                <ol-feature :key="p_site_feature.id"
                            :properties="{  id: p_site_feature.id,
                                                  properties: {
                                                    'site_type': p_site_feature.site_type
                                                  }}"
                >
                    <ol-geom-point
                            :coordinates="p_site_feature != undefined ? p_site_feature.coordinates : undefined">
                    </ol-geom-point>
                </ol-feature>
                <ol-style :overrideStyleFunction="siteStyleFunction"></ol-style>
            </ol-source-vector>
        </ol-vector-layer>


    </ol-map>
</template>
<script>
import Icon from "ol/style/Icon";
import NoneMarkerIcon from '@/assets/images/none_marker_25x25.png'
import StationData from "../utilities/station_data";

//import FeatureUtils from "@/utilities/feature_funcs";

export default {

  name: 'SingleSiteMap',
  props: {
      p_site_feature: {type: StationData, default: undefined}
  },
  components: {
  },

  data() {
    return {
      zoom: 15,
      center: [0, 0],
      rotation: 0,
    }
  },
  created() {
    console.debug("SingleSiteMap created. Center pt: " + this.p_site_feature.coordinates);
  },
  mounted() {
    console.debug("SingleSiteMap mounted.");
    //let feature_extent = this.$refs.site_vector_source.source.getExtent();
    //this.$refs.single_site_view.fit(feature_extent);
    this.center = [this.p_site_feature.coordinates[0], this.p_site_feature.coordinates[1]];
    this.zoom = 15;

  },
  methods: {
      siteStyleFunction(feature, style) {
          console.debug("siteStyleFunction called.");
          let icon_scale = 0.75;
          let properties = feature.getProperties();
          let site_type = properties.site_type;
          let icon = new Icon({
              src: NoneMarkerIcon,
              scale: icon_scale
          });
          if(site_type == 'Water Quality') {
              let icon_scale = 0.75;
              icon = new Icon({
                  src: NoneMarkerIcon,
                  scale: icon_scale
              });
          }
          style.setImage(icon);
          return(style);
      }
  },
  computed: {
      site_feature: function() {
          if(this.p_site_feature !== undefined) {
              return(this.p_site_feature[0]);
          }
          return undefined;
      }
  }
}
</script>
